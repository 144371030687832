<template>
    <component :is="tag">
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 24 24"
             :style="{ height: `${size}px`, width: `${size}px` }"
             aria-hidden="true"
             :class="iconClasses"
             class="inline fill-current">
            <path :d="path" />
        </svg>
    </component>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import type { PropType } from 'vue';
import {
    mdiMenu,
    mdiFoodVariant,
    mdiArrowLeft,
    mdiPlus,
    mdiClose,
    mdiHelpCircleOutline,
    mdiPencil,
    mdiCupcake,
    mdiArrowRight,
    mdiTimerOutline,
    mdiAlertCircle,
    mdiHome,
    mdiDelete,
    mdiAlert,
    mdiHeartOutline,
    mdiHeart,
    mdiOpenInNew,
    mdiEye,
    mdiEyeOff,
    mdiMagnify,
    mdiPartyPopper,
    mdiCheck,
    mdiUpload,
    mdiPause,
    mdiCached,
    mdiBeer,
    mdiPackage,
    mdiAccount,
    mdiAt,
    mdiEmailFastOutline,
    mdiMapMarker,
    mdiAccountGroup,
    mdiViewGallery,
    mdiShareVariant,

    // tiptap editor
    mdiFormatQuoteClose,
    mdiFormatListBulleted,
    mdiMinus,
    mdiOrderNumericAscending,
    mdiFormatHeader1,
    mdiFormatHeader2,
    mdiFormatHeader3,
    mdiFormatHeader4,
    mdiFormatBold,
    mdiFormatUnderline,
    mdiFormatItalic,
    mdiFormatClear,
    mdiUndo,
    mdiRedo,
    mdiFormatAlignCenter,
    mdiFormatAlignLeft,
    mdiFormatAlignJustify
} from '@mdi/js';

const icons = {
    menu: mdiMenu,
    ingredients: mdiFoodVariant,
    arrowLeft: mdiArrowLeft,
    arrowRight: mdiArrowRight,
    plus: mdiPlus,
    close: mdiClose,
    help: mdiHelpCircleOutline,
    edit: mdiPencil,
    product: mdiCupcake,
    stopwatch: mdiTimerOutline,
    alertCircle: mdiAlertCircle,
    home: mdiHome,
    delete: mdiDelete,
    alert: mdiAlert,
    heartOutline: mdiHeartOutline,
    heart: mdiHeart,
    openInNew: mdiOpenInNew,
    eye: mdiEye,
    eyeOff: mdiEyeOff,
    magnify: mdiMagnify,
    party: mdiPartyPopper,
    check: mdiCheck,
    upload: mdiUpload,
    pause: mdiPause,
    refresh: mdiCached,
    unit: mdiBeer,
    box: mdiPackage,
    mail: mdiAt,
    account: mdiAccount,
    email: mdiEmailFastOutline,
    location: mdiMapMarker,
    socials: mdiAccountGroup,
    gallery: mdiViewGallery,
    share: mdiShareVariant,

    // tiptap editor
    blockquote: mdiFormatQuoteClose,
    bulletList: mdiFormatListBulleted,
    orderedList: mdiOrderNumericAscending,
    minus: mdiMinus,
    h1: mdiFormatHeader1,
    h2: mdiFormatHeader2,
    h3: mdiFormatHeader3,
    h4: mdiFormatHeader4,
    bold: mdiFormatBold,
    underline: mdiFormatUnderline,
    italic: mdiFormatItalic,
    formatClear: mdiFormatClear,
    undo: mdiUndo,
    redo: mdiRedo,
    alignCenter: mdiFormatAlignCenter,
    alignLeft: mdiFormatAlignLeft,
    alignJustify: mdiFormatAlignJustify
} as const;

export default defineComponent({
    name: 'Icon',

    props: {
        name: {
            type: String as PropType<keyof typeof icons>,
            required: true
        },

        size: {
            type: [Number, String],
            default: 24,
            validator: (val) => {
                return !isNaN(Number(val));
            }
        },

        tag: {
            type: String as PropType<keyof HTMLElementTagNameMap>,
            default: 'span'
        },

        iconClasses: {
            type: String,
            default: 'h-5'
        }
    },

    setup: (props) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const path = computed(() => icons[props.name]);

        return {
            path
        };
    }
});
</script>
